import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || '',
    username: localStorage.getItem("username") || '',
    remember: localStorage.getItem("remember") || '',
    platform: localStorage.getItem("platform") || ''
  },
  getters: {
  },
  //同步修改state内容
  mutations: {
    SET_TOKEN:(state, token) => {
      state.token = token;
    },
    SET_USERNAME:(state, username) => {
      state.username = username;
    },
    SET_REMEMBER:(state, remember)=>{
      state.remember = remember;
    },
    SET_PLATFORM:(state, platform)=>{
      state.platform = platform;
    }
  },
  //异步修改state内容
  actions: {
    setToken(context, token){
      context.commit('SET_TOKEN', token);
    },
    clearToken(context){
      context.commit("SET_TOKEN", "");
    },
    setUsername(context, username){
      context.commit('SET_USERNAME', username);
    },
    clearUsername(context){
      context.commit('SET_USERNAME', '');
    },
    setRemember(context, remember){
      context.commit('SET_REMEMBER', remember);
    },
    clearRemember(context){
      context.commit('SET_REMEMBER', '');
    },
    setPlatform(context, platform){
      context.commit('SET_PLATFORM', platform);
    },
    clearPlatform(context){
      context.commit('SET_PLATFORM', '');
    }
  },
  modules: {
  }
})
